function toggleHelpDropDown(btnId, dropdownId) {
    if(!btnId || !dropdownId) { return; }
    var btn = document.getElementById(btnId);
    var dropdown = document.getElementById(dropdownId);
    if(!btn || !dropdown) { return; }
    if(btn.classList.contains('collapsed')) {
        btn.classList.add('collapse');
        btn.classList.add('in');
        btn.classList.remove('collapsed')
        dropdown.classList.add('in');
    } else {
        btn.classList.remove('collapse');
        btn.classList.remove('in');
        btn.classList.add('collapsed');
        dropdown.classList.remove('in');
    }
}